import React, { useState, useEffect } from 'react';
import './Quotes.scss';

const quotes = [
  "Ravena, let the vibrant reds and soft whites of life inspire your every step.",
  "Your beauty, Ravena, shines brightly in every smile and kind gesture.",
  "Find joy in the melody of life and let music lift your spirits, Ravena.",
  "Ravena, your strength and grace remind us of the elegance of a red rose in bloom.",
  "Take a moment to savor the flavors of life, just as you would a delicious meal.",
  "With every long walk, Ravena, find peace and clarity within yourself.",
  "Ravena, you are as unique and beautiful as a snowflake dancing in a sea of red.",
  "Let the warmth of music fill your heart and guide you through the day, Ravena.",
  "Ravena, your inner strength is as bold and vivid as the color red.",
  "Embrace the journey of life with grace and resilience, just like Harry Potter, Ravena.",
  "Find serenity and inspiration in the quiet moments of a long walk, Ravena.",
  "Ravena, let the pure white of peace and red of passion guide you to greatness.",
  "Savor the beauty in every day, from a delicious meal to a stunning sunset, Ravena.",
  "Let the music play, Ravena, and dance to the rhythm of your own heartbeat.",
  "Ravena, you have the courage to face your fears and the strength to overcome them.",
  "With every step on your journey, Ravena, draw strength from within.",
  "Ravena, you are as enchanting as a melody and as strong as a symphony.",
  "Let the colors of life paint your world in shades of joy and contentment, Ravena.",
  "Find strength in the quiet moments, and let peace fill your heart, Ravena.",
  "Ravena, your beauty is not just in your appearance, but in your actions and kindness.",
  "Let the music of life inspire you and fill your days with joy, Ravena.",
  "Ravena, your journey is a canvas, paint it with bold strokes and vibrant colors.",
  "With every long walk, Ravena, find new paths and possibilities.",
  "Ravena, let the reds and whites of life bring passion and purity to your days.",
  "Embrace the beauty within and let it shine, Ravena. You are truly beautiful.",
  "Savor the sweet moments, Ravena, and let happiness be your constant companion.",
  "Ravena, you are as magical as the world of Harry Potter, full of possibilities.",
  "Let the rhythm of life inspire you and guide you, Ravena. Dance to your own beat.",
  "With grace and strength, Ravena, you light up the world around you.",
  "Ravena, your journey is as unique as a rare red and white rose in full bloom.",
  "Find solace in music, Ravena, and let it be a source of strength and comfort.",
  "Embrace the journey, Ravena, and let the reds and whites guide you to happiness.",
  "Savor the flavors of life, Ravena, and find joy in every bite.",
  "Ravena, you are as radiant as a red sunset and as peaceful as a white cloud.",
  "Let the magic of Harry Potter inspire you, Ravena, and embrace your own magic.",
  "Find strength in long walks, Ravena, and let nature be your guide.",
  "Ravena, your beauty shines from within, radiating kindness and grace.",
  "Let the music fill your soul, Ravena, and dance to the beat of your own drum.",
  "Embrace the vibrant reds and peaceful whites of life, Ravena. You are strong.",
  "Savor every moment, Ravena, and find beauty in the simple pleasures of life.",
  "Ravena, you are as enchanting as a melody and as graceful as a dance.",
  "Let the colors of life paint your world in shades of love and happiness, Ravena.",
  "Find your rhythm, Ravena, and let the music guide you through life's dance.",
  "Ravena, your beauty is like a rare red rose, unique and captivating.",
  "With every step, Ravena, find strength and grace. You are a force of nature.",
  "Ravena, you are as magical as the world of Harry Potter, full of wonder and adventure.",
  "Let the reds and whites of life inspire you, Ravena, and guide you to greatness.",
  "Embrace your journey, Ravena, and let the colors of life paint your path.",
  "Savor the sweetness of life, Ravena, and find joy in every moment.",
  "Ravena, your strength is as bold as red, and your spirit is as pure as white.",
  "Let the music play, Ravena, and fill your life with melodies of joy and strength."
];


const Quotes: React.FC = () => {
  const [currentQuote, setCurrentQuote] = useState<string>("");
  const [displayedText, setDisplayedText] = useState<string>("");

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setCurrentQuote(quotes[randomIndex]);
  }, []); // The empty dependency array ensures this effect runs once when the component mounts

  useEffect(() => {
    let index = 0;
    const typingSpeed = 50; // Adjust typing speed (lower value = faster typing)

    const typeText = () => {
      if (index <= currentQuote.length) {
        setDisplayedText(currentQuote.substring(0, index));
        index++;
        setTimeout(typeText, typingSpeed);
      }
    };

    typeText(); // Start typing effect immediately

  }, [currentQuote]);

  return <div className="quote">{displayedText}</div>;
};

export default Quotes;