import React, { useEffect, useRef } from 'react';
import './App.css';
import Quotes from './Quotes';
import Footer from './Footer'; // Ensure this is properly imported if used

function App() {
  const audioRef = useRef(new Audio('/background-music.mp3'));

  useEffect(() => {
    const audio = audioRef.current;
    
    // Start playing the music muted
    audio.muted = true;
    audio.volume = 0.2;
    audio.loop = true;

    audio.play()
      .then(() => {
        console.log('Playback started successfully');
        // Unmute the audio after 1 second
        setTimeout(() => {
          audio.muted = false;
        }, 1000);
      })
      .catch(error => {
        console.error('Playback failed:', error);
      });

    // Stop the music when the component unmounts
    return () => audio.pause();
  }, []);

  return (
    <div className="App">
      <Quotes />
      {/* <Footer /> Uncomment this line if you want to use the Footer component */}
    </div>
  );
}

export default App;
